import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { GoSearch } from "react-icons/go";
import { translate } from "../services/i18n";
import DatePicker from "react-date-picker";
import "react-date-picker/dist/DatePicker.css";
import "react-calendar/dist/Calendar.css";
import "./filter.css";

const Container = styled.div`
  background-color: rgba(0, 0, 0, 0.7);
  margin-bottom: 15px;
`;

const RemoveButton = styled.button`
  background-color: transparent;
  border: none;
  color: red;
  font-weight: bold;
  cursor: pointer;
  &:hover {
    color: #ff0000aa;
  }
`;

const SearchInput = styled.input`
  border: none;
  background-color: transparent;
  border-radius: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  color: white;
  box-shadow: none;
  outline: none;
  padding: 0.5rem;
  width: 100%;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #888888aa;
`;

const DivFilters = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1rem;
  color: "white";
  padding: 0.3rem;
`;

const Expanded = styled.div`
  color: white;
  font-size: 14px;
  padding: 10px;

  p {
    margin-top: 0;
  }
`;

const Filters = (props) => {
  const { filters, onSelectFilter, searchValue, setSearchValue } = props;

  const [value, setValue] = useState("");
  const [currentFilter, setCurrentFilter] = useState("date");

  let remove = "remove";

  useEffect(() => {
    if (currentFilter.values) {
      onSelectFilter("");
    }
  }, []);

  return (
    <>
      {
        <Container>
          <Header style={{ alignItems: "center", paddingLeft: "1rem" }}>
            <div
              style={{
                color: "orange",
              }}
            >
              {value === ""
                ? `${translate("filters.cumulative")}`
                : `${translate("filters.day")} ${new Date(
                    value
                  ).toLocaleDateString(translate("tag"))}`}
            </div>
            <div
              style={{
                color: "white",
                display: "flex",
                flexDirection: "row",
              }}
            >
              <DivFilters>
                <RemoveButton
                  onClick={(e) => {
                    onSelectFilter(currentFilter.filter);
                    setSearchValue("");
                    setValue("");
                  }}
                >
                  {translate(remove)}
                </RemoveButton>
                {filters &&
                  filters.map((el) => {
                    const filter = el;

                    let active =
                      filter === currentFilter.filter ? "active" : "";

                    return (
                      <p
                        className={active}
                        key={filter}
                        style={{ color: "white" }}
                      >
                        {translate(`filters.${filter}`, {
                          defaultValue: filter,
                        })}
                      </p>
                    );
                  })}
                <Expanded>
                  {currentFilter === "" ? null : (
                    <DatePicker
                      minDate={new Date("2024/04/25")}
                      onChange={(date) => {
                        setValue(date);
                        onSelectFilter(date);
                        if (date === null) {
                          setValue("");
                        }
                      }}
                      value={value}
                    />
                  )}
                </Expanded>
              </DivFilters>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  gap: "1rem",
                  backgroundColor: "#605a5a",
                  opacity: "0.5",
                  padding: "0 0.5rem 0rem 0.5rem",
                }}
              >
                <SearchInput
                  className="white-placeholder"
                  type="text"
                  value={searchValue}
                  placeholder={translate("filters.search")}
                  onChange={(e) => setSearchValue(e.target.value)}
                />
                <GoSearch />
              </div>
            </div>
          </Header>
        </Container>
      }
    </>
  );
};

export default Filters;
