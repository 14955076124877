import React from "react";
import styled from "styled-components";
import { translate } from "../services/i18n";

const RankTable = styled.div`
  table {
    min-width: fit-content;
    width: 100%;
  }

  tr:nth-child(even) {
    background-color: #f1f1f1;
  }
  th {
    background-color: #eaeaea;
  }

  th,
  td {
    text-align: center;
    padding: 10px;
  }

  @media (min-width: 780px) {
    max-height: 70vh;
  }
`;

const Table = (props) => {
  const { data, search } = props;

  if (!Array.isArray(data) || !data[0]) return <></>;

  const filteredData = search
    ? data.filter((row) =>
        row.name.toLowerCase().includes(search.toLowerCase())
      )
    : data;

  return (
    <RankTable>
      <table>
        <thead>
          <tr>
            <th>{translate("categories.position")}</th>
            {Object.keys(data[0]).map((key) => {
              switch (key) {
                case "name":
                  return <th key={key}>{translate("categories.name")}</th>;
                case "kills":
                  return <th key={key}>{translate("categories.kills")}</th>;
                case "deaths":
                  return <th key={key}>{translate("categories.deaths")}</th>;
                case "points":
                  return <th key={key}>{translate("categories.points")}</th>;
                default:
                  break;
              }
              return <th key={key}>{key}</th>;
            })}
          </tr>
        </thead>
        <tbody>
          {filteredData.map((row, index) => {
            return (
              <tr key={index}>
                <td>{index + 1}º</td>
                {Object.keys(row).map((value) => {
                  return <td key={value}>{row[value]}</td>;
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </RankTable>
  );
};

export default Table;
