export const pt = {
  tag: "pt-BR",
  header: {
    title: "Arena Real: Sangue e Honra Ranking",
    description: "Descubra os jogadores mais insanos do servidor",
  },
  categories: {
    title: "Categorias",
    level: "Nível",
    kills: "Abates",
    deaths: "Mortes",
    points: "Pontos",
    name: "Nome",
    position: "Posição",
    raWins: "Vitórias na Arena Real",
    raKills: "Abates na Arena Real",
    seasonLevel: "Servidor de Temporada",
    fameGuild: "Fame Guild",
  },
  filters: {
    date: "Filtrar por Data",
    search: "Pesquisar por nickname",
    cumulative: "Pontuação Total",
    day: "Visualização do dia ",
  },
  remove: "Remover todos os filtros",
  activefilter: "Filtro Ativo",
};
