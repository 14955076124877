import "./App.css";
import Navbar from "./components/navbar";
import Body from "./components/body";

import { setLanguageToI18n } from "./services/i18n";

function App() {
  const params = new URL(window.location.href).searchParams;
  setLanguageToI18n(params.has("lang") ? params.get("lang") : "en_US");

  return (
    <div className="app" datatest-id="app-div">
      <Navbar></Navbar>
      <Body></Body>

      <div className="background" datatest-id="app-background"></div>
    </div>
  );
}

export default App;
