export const en = {
  tag: "en-US",
  header: {
    title: "Royal Arena: Blood and Honor Ranking.",
    description: "Discover the most insane players on the server",
  },
  categories: {
    title: "Categories",
    level: "Level",
    kills: "Kills",
    deaths: "Deaths",
    points: "Points",
    name: "Name",
    position: "Position",
    raWins: "Royal Arena Wins",
    raKills: "Royal Arena Kills",
    seasonLevel: "Season Server",
    fameGuild: "Fame Guild",
  },
  filters: {
    date: "Filter by Date",
    search: "Search by nickname",
    cumulative: "Total Score",
    day: "View of ",
  },
  remove: "Remove all filters",
  activefilter: "Active Filter",
};
